@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.GalleryTitle{color:white;font-size:20px;font-family:'Source Code Pro', monospace;padding-left:6px}.GallerySubTitle{color:white;font-size:14px;padding-bottom:10px;font-family:'Source Code Pro', monospace;padding-left:6px}*{line-height:1.5}a{text-decoration:none !important;color:#d66061}a:hover{border-bottom:solid 2px var(--secondary);margin-bottom:-2px}.App{height:100vh;width:100vw;background:#212121;overflow-x:hidden;-ms-overflow-style:none}.App::-webkit-scrollbar{display:none}.Content{padding:20px;padding-top:20px;box-sizing:border-box;height:100%;margin:auto;overflow-x:hidden}@media all and (max-width: 768px){.Container{padding:10px}}

