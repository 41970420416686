@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400&display=swap');


.GalleryTitle {
  color: white;
  font-size: 20px;
  font-family: 'Source Code Pro', monospace;
  padding-left: 6px;
}

.GallerySubTitle {
  color: white;
  font-size: 14px;
  padding-bottom: 10px;
  font-family: 'Source Code Pro', monospace;
  padding-left: 6px;

}

* {
  line-height: 1.5;
}


a {
  text-decoration: none !important;
  color: #d66061;
}

a:hover {
  border-bottom: solid 2px var(--secondary);
  margin-bottom: -2px;
}

.App {
  height: 100vh;
  width: 100vw;
  background: #212121;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  
    -ms-overflow-style: none;
}

.Content {
  padding: 20px;
  padding-top: 20px;
  box-sizing: border-box;
  // max-width: 732px;
  height: 100%;
  margin: auto;
  overflow-x: hidden;
}


/* Mobile Styles*/
@media all and (max-width: 768px) {
  .Container {
    padding: 10px;
  }
}
